html {
  box-sizing: border-box;
  font-family: "PingFang SC", "Microsoft YaHei";
}
*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}

.main-page {
  width: 100%;
  height: 100%;
  min-width: 1100px;
  position: relative;
  background-image: url("../resource/bg-top.svg"),
    url("../resource/bg-left.svg"), url("../resource/bg-right.svg"),
    url("../resource/bg-bottom.svg"), url("../resource/background.svg");
  background-position: top, left, right, bottom, 0;
  background-size: 90% auto, auto 95%, auto 95%, 100.4% auto, cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.main-page h1 {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  padding-top: 50px;
  padding-left: 78px;
}

.main-page .cards {
  display: flex;
  gap: 20px 60px;
  justify-content: space-between;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 40px;
  height: 218px;
  background-image: url("../resource/card.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.card-content .sec-title {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  width: 170px;
  height: 40px;
  padding: 0 20px;
  margin-left: 10px;
  background-image: url("../resource/sec-title-bg.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.card-content .icon {
  width: 98px;
  height: 84px;
}
.card-content:hover {
  background-image: url("../resource/card-hover.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.main-page .main-container {
  width: 100%;
  padding: 0 56px;
}

.main-page .container {
  width: 100%;
  height: 80%;
  padding: 0 130px;
  margin-top: 60px;
}

.main-page .title-container {
  display: flex;
  justify-content: space-between;
}
.main-page .title-container .btn {
  width: 136px;
  height: 27px;
  cursor: pointer;
}

.main-page .main-title {
  width: 265px;
  height: 39px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: left;
  position: relative;
  background-image: url("../resource/title-bg.svg");
}
.main-page .main-title img {
  position: absolute;
  right: 60px;
  top: -10px;
}
.mes-test-icon {
  right: 80px !important;
}
.main-title span {
  position: absolute;
  top: -14px;
  left: 24px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}

@media (max-width: 1920px) {
  .main-page {
    zoom: 0.9;
  }
  .card-content {
    zoom: 0.9;
  }
}

@media (max-width: 1600px) {
  .main-page {
    zoom: 0.8;
  }
  .card-content {
    zoom: 0.7;
  }
}

@media (max-width: 1440px) {
  .main-page {
    zoom: 0.74;
  }
  .card-content {
    zoom: 0.65;
  }
}
@media (max-width: 1100px) {
  .main-page {
    zoom: 0.68;
  }
  .card-content {
    zoom: 0.6;
  }
}
